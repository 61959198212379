<template>
  <v-dialog
    v-model="isOpened"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-container>
        <v-form
          @submit.prevent="onSubmit"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-text-field
              label="Email"
              required
              v-model="modalUser.email"
              @input="resetErrors"
              :error-messages="errors.email"
            />

            <v-text-field
              label="Имя"
              required
              v-model="modalUser.name"
              @input="resetErrors"
              :error-messages="errors.name"
            />

            <v-text-field
              label="Телефон"
              required
              v-model="modalUser.phone"
            />

            <v-select
              :items="districts.map(item => ({ value: item.id, text: item.name }))"
              label="Район"
              multiple
              v-model="modalUser.district_ids"
            />

            <v-text-field
              autocomplete="current-password"
              :rules="[rules.password]"
              v-model="modalUser.password"
              label="Пароль"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="onCancelClick"
            >
              Отмена
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              type="submit"
              :disabled="!valid"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { ListsService, UsersService } from "@/common/api.service";

export default {
  props: {
    user: {
      default: null
    }
  },

  data() {
    return {
      types: [],
      districts: [],
      modalUser: {
        name: null,
        email: null,
        phone: null,
        district_ids: [],
        type_id: null,
        password: null
      },
      isOpened: false,

      valid: true,
      rules: {
        required: value => !!value || "Поле обязательно для заполнения.",
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

          if (this.user && this.user.id) {
            return (
              (!value || pattern.test(value)) ||
              "Мин. 8 символов, как минимум одна заглавная буква, число и спецсимвол (!@#$%^&*)."
            )
          } else {
            return (
              pattern.test(value) ||
              "Мин. 8 символов, как минимум одна заглавная буква, число и спецсимвол (!@#$%^&*)."
            )
          }
        }
      },

      errors: {},
    }
  },

  methods: {
    onSubmit() {
      if (this.user.id) {
        UsersService.update(this.user.id, this.modalUser).then(() => this.$emit('close', true))
      } else {
        UsersService.add(this.modalUser, 'company')
          .then(() => this.$emit('close', true))
          .catch(error => {
            if (error.response && 422 === error.response.status) {
              this.errors = error.response.data.errors
            }
          })
      }
    },

    onCancelClick() {
      this.$emit('close', false)
    },

    resetErrors () {
      this.errors = {}
    }
  },

  watch: {
    user (value) {
      this.modalUser.password = null

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }

      if (value) {
        this.isOpened = true

        for (const [key] of Object.entries(this.modalUser)) {
          this.modalUser[key] = this.user[key]
        }

        this.modalUser.district_ids = this.user && this.user.districts ? this.user.districts.map(item => item.id) : []
      } else {
        this.isOpened = false
      }
    }
  },
  beforeMount() {
    ListsService.get(['types', 'districts']).then(({data}) => {
      this.types = data.types
      this.districts = data.districts
    })
  }
}
</script>
